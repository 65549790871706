import { isEmpty } from 'lodash';
import { i18n } from 'next-i18next';

import {
  SaveModuleCertificateCreateRequest,
  ModuleCertificateCreateState,
  LearningPathStepListRequest,
  CourseSetRequest,
  CategorySelectListRequest,
} from '@redux/learningPath/type';
import { CertificateType } from '@common/enum/learningPath';
import { CourseSelectListType } from 'src/types/learningPath';

type MapSaveModuleCertificateCreateRequestPayload = {
  data: ModuleCertificateCreateState;
  isDraft: boolean;
  isBundleChange: boolean;
  isReactivate?: boolean;
};

export const mapSaveModuleCertificateCreateRequest = ({
  data,
  isDraft,
  isBundleChange,
  isReactivate = false,
}: MapSaveModuleCertificateCreateRequestPayload): SaveModuleCertificateCreateRequest => {
  const certificateInstructorListFiltered = data.certificateInstructors.flatMap((instructor) =>
    instructor && instructor.id !== 0 ? instructor.id : [],
  );

  const learningPathStepList: LearningPathStepListRequest[] = data.learningPathStepList.map((step, stepIndex) => {
    const courseSet: CourseSetRequest[] = step.courseSet.map((courseSetItem, courseSetIndex) => {
      return {
        order: courseSetIndex + 1,
        courseCertificateBundleCondition: courseSetItem.courseCertificateBundleCondition,
        requiredCondition: isEmpty(courseSetItem.requiredCondition)
          ? undefined
          : Number(courseSetItem.requiredCondition),
        certificateType: courseSetItem.certificateType,
        courseIdList: courseSetItem.courseSelectList.map((course) => course.id),
        categorySelectList: courseSetItem.categorySelectList.map<CategorySelectListRequest>((category) => {
          return {
            categoryId: category.category.id === 0 ? category.parentCategory.id : category.category.id,
            certificateBundleCondition: category.certificateBundleCondition,
            requiredCondition: isEmpty(category.requiredCondition) ? undefined : Number(category.requiredCondition),
          } as CategorySelectListRequest;
        }),
      } as CourseSetRequest;
    });
    return {
      step: stepIndex + 1,
      conditionRelation: step.conditionRelation,
      courseSet,
    } as LearningPathStepListRequest;
  });

  return {
    id: data.id,
    isBundleChange,
    isDraft,
    isReactivate,
    name: data.learningPathName,
    slugName: data.slugName,
    description: data.description,
    certificatePreviewImage: data.certificatePreviewImage,
    instructorIdList: certificateInstructorListFiltered,
    productId: data.courseBundle?.productId,
    learningPathStepList,
    cardImage: data.cardImage?.key,
    bannerCoverImage: data.bannerCoverImage?.key,
    lineImage: data.lineImage?.key,
    facebookImage: data.facebookImage?.key,
    facebookTitle: data.facebookTitle,
    facebookDescription: data.facebookDescription,
    isUseMailContent: data.isUseMailContent,
    mailSubject: data.mailSubject,
    mailContent: data.mailContent,
    twitterContent: data.twitterContent,
    googleDescription: data.googleDescription,
  } as SaveModuleCertificateCreateRequest;
};

export const mapModuleCertificateCreateResponseToState = (
  data: ModuleCertificateCreateState,
): ModuleCertificateCreateState => {
  const result = {
    ...data,
    learningPathStepList: data.learningPathStepList.map((step) => ({
      ...step,
      conditionRelation: step.courseSet.length > 1 ? step.conditionRelation : undefined,
      courseSet: step.courseSet.map((courseSetStep) => ({
        ...courseSetStep,
        courseCertificateBundleCondition:
          courseSetStep.certificateType === CertificateType.COURSE && courseSetStep.courseSelectList.length === 1
            ? undefined
            : courseSetStep.courseCertificateBundleCondition,
        courseSelectList: courseSetStep.courseSelectList.map((course) => {
          const courseExtendedCourseId = course as CourseSelectListType & { courseId: number };
          return {
            ...course,
            id: courseExtendedCourseId.courseId,
          };
        }),
        requiredCondition: courseSetStep.requiredCondition !== null ? courseSetStep.requiredCondition.toString() : '',
        categorySelectList: courseSetStep.categorySelectList.map((category) => ({
          ...category,
          parentCategory: category.parentCategory.id === null ? category.category : category.parentCategory,
          requiredCondition: category.requiredCondition !== null ? category.requiredCondition.toString() : '',
          category:
            category.parentCategory.id === null
              ? {
                  id: 0,
                  name: `${i18n?.t('common:all')}`,
                  enabled: true,
                }
              : category.category,
        })),
      })),
    })),
  };
  return result;
};
