export enum CarouselArrowDirection {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum CropImageObjectFit {
  Fill = 'fill',
  Contain = 'contain',
  Cover = 'cover',
  None = 'none',
  ScaleDown = 'scale-down',
}

export enum CertificateType {
  COURSE = 'COURSE',
  CATEGORY = 'CATEGORY',
}

export enum CertificateBundleCondition {
  ALL = 'ALL',
  ANY = 'ANY',
  SOME = 'SOME',
}

export enum ConditionRelation {
  AND = 'AND',
  OR = 'OR',
}

export enum UploadLearningPath {
  LINE = 'LINE',
  FACEBOOK = 'FACEBOOK',
  BANNER = 'BANNER',
  CARD = 'CARD',
  CERTIFICATE_PREVIEW = 'CERTIFICATE_PREVIEW',
}

export enum SaveStatus {
  DRAFT = 'draft',
  CHANGE = 'change',
  PUBLISH = 'publish',
}

export enum LearningPathReceivedStatus {
  PENDING = 'pending',
  NOT_RECEIVED = 'not_received',
  RECEIVED = 'received',
  RECEIVED_RETROSPECTIVELY = 'received_retrospectively',
}

export enum LearningPathStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum CourseCategoryDialogSelectName {
  PARENT_CATEGORY = 'parentCategory',
  CATEGORY = 'category',
  CERTIFICATE_BUNDLE_CONDITION = 'certificateBundleCondition',
  REQUIRED_CONDITION = 'requiredCondition',
}

export enum BasicInformationField {
  SLUG_NAME = 'slugName',
}

export enum UploadImageField {
  CARD_IMAGE = 'cardImage',
  BANNER_COVER_IMAGE = 'bannerCoverImage',
  LINE_IMAGE = 'lineImage',
  FACEBOOK_IMAGE = 'facebookImage',
  IS_USE_MAIL_CONTENT = 'isUseMailContent',
}
