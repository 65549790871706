import { pickBy } from 'lodash';
import config from 'src/config';
import Http from 'src/helpers/httpClient';

import httpClient from '@helpers/httpClient';
import {
  StartInteractionAnswerPayload,
  CreateOrUpdateFinishedLogPayload,
  SubmitInteractionAnswerPayload,
  QuizSubmissionPayload,
  UserAnswers,
} from '@interfaces/learning';
import { StartQuizParams } from '@interfaces/quiz';
import { IUserOfficialInfo } from '@interfaces/user';

const END_POINT = 'v1/learning';
const END_POINT_V2 = 'v2/enrollment';

export const LEARNING_END_POINT = {
  course: (courseIdName: string) => `${END_POINT}/courses/${courseIdName}`,
  CHAPTER: (id: number) => `${END_POINT}/chapters/${id}`,
  CHAPTER_PREVIEW: (id: number) => `${END_POINT}/chapters/${id}/preview`,
  START_QUIZ: (courseIdName: string) => `${END_POINT}/courses/${courseIdName}/start-quiz`,
  INTERACTION_ANSWER: (id: number) => `${END_POINT}/interactive-contents/${id}/submit-answer`,
  QUIZ_ANSWER: (courseItemId: number) => `${END_POINT}/courses/quizzes/${courseItemId}`,
  QUIZ_SUMMARY_SCORE: (quizId: number) => `${END_POINT}/courses/quizzes/${quizId}/summary-score`,
  CREATE_OR_UPDATE_FINISHED_LOG: (courseIdOrIdName: string) => `${END_POINT}/courses/${courseIdOrIdName}/finished-logs`,
  GET_QUIZ_SUBMISSION: (quizId: number) => `${END_POINT}/courses/quizzes/${quizId}/quizSubmission`,
  CHECK_ALLOWED: (courseIdOrIdName: string) => `${END_POINT}/courses/${courseIdOrIdName}/check-learning-page-allowed`,
  VALIDATE_EXAM_PROFILE: (courseIdName: string) => `${END_POINT}/courses/${courseIdName}/validateExamProfile`,
  START_INTERACTION_ANSWER: (id: number) => `${END_POINT}/interactive-contents/${id}/start-interaction`,
  SUBMIT_INTERACTION_ANSWER: (id: number, interactiveContentSubmissionId: number) =>
    `${END_POINT}/interactive-contents/${id}/submission/${interactiveContentSubmissionId}`,
  SEND_INTERACTION_ANSWER: (id: number) => `${END_POINT}/interactive-contents/${id}/send-answer`,
  CLOSE_INTERACTION_ANSWER: (id: number, interactiveContentSubmissionId: number) =>
    `${END_POINT}/interactive-contents/${id}/close-answer/${interactiveContentSubmissionId}`,
  MARK_AS_SEEN_CREDIT_PLUS_ALERT_MODAL: (courseIdOrIdName: string) =>
    `${END_POINT}/courses/${courseIdOrIdName}/credit-plus-alert`,
  UPLOAD_FILE_ANSWER: (quizSubmissionId: number, questionId: number) =>
    `${END_POINT}/upload-file-answer/${quizSubmissionId}/${quizSubmissionId}`,
  EXPORT_INTERACTION: (id: number) => `${END_POINT}/interactive-contents/${id}/export`,
  GET_CERTIFICATE_PROGRESSION: (courseIdOrIdName: string) => `${END_POINT_V2}/courses/${courseIdOrIdName}/certificate`,
  UPDATE_LEARNING_PATH_PROGRESSION: () => 'v1/learning-path/progression',
};

export default {
  fetchLearningCourse(payload: any) {
    const { courseId, courseItemId } = payload;
    let queryString = '';
    if (courseItemId) {
      queryString += `courseItemId=${encodeURIComponent(courseItemId)}`;
    }
    const apiUrl = `${config.API_DOMAIN}/${LEARNING_END_POINT.course(courseId)}?${queryString}`;
    return Http.get(apiUrl, { isPublic: false });
  },
  fetchChapter(id: number) {
    const apiUrl = `${config.API_DOMAIN}/${LEARNING_END_POINT.CHAPTER(id)}`;
    return Http.get(apiUrl, { isPublic: false });
  },
  fetchChapterPreview(id: number) {
    const apiUrl = `${config.API_DOMAIN}/${LEARNING_END_POINT.CHAPTER_PREVIEW(id)}`;
    return Http.get(apiUrl, { isPublic: false });
  },
  startQuiz(payload: StartQuizParams) {
    const { courseIdName } = payload;
    const apiUrl = `${config.API_DOMAIN}/${LEARNING_END_POINT.START_QUIZ(courseIdName)}`;
    return Http.post(apiUrl, payload);
  },
  startInteractionContent(id: number, payload: StartInteractionAnswerPayload) {
    const apiUrl = `${config.API_DOMAIN}/${LEARNING_END_POINT.START_INTERACTION_ANSWER(id)}`;
    return Http.post(apiUrl, payload);
  },
  submitInteractionContent(
    id: number,
    interactiveContentSubmissionId: number,
    payload: SubmitInteractionAnswerPayload,
  ) {
    const apiUrl = `${config.API_DOMAIN}/${LEARNING_END_POINT.SUBMIT_INTERACTION_ANSWER(
      id,
      interactiveContentSubmissionId,
    )}`;
    return Http.patch(apiUrl, payload);
  },
  sendAnswerInteractionContent(id: number, payload: SubmitInteractionAnswerPayload) {
    const apiUrl = `${config.API_DOMAIN}/${LEARNING_END_POINT.SEND_INTERACTION_ANSWER(id)}`;
    return Http.post(apiUrl, payload);
  },
  closeInteractionContent(id: number, interactiveContentSubmissionId: number, payload: SubmitInteractionAnswerPayload) {
    const apiUrl = `${config.API_DOMAIN}/${LEARNING_END_POINT.CLOSE_INTERACTION_ANSWER(
      id,
      interactiveContentSubmissionId,
    )}`;
    return Http.patch(apiUrl, payload);
  },
  submitQuizAnswer(payload: QuizSubmissionPayload) {
    const { quizSubmissionId, quizSubmission } = payload;
    const { userAnswers } = quizSubmission;

    const apiUrl = `${config.API_DOMAIN}/${LEARNING_END_POINT.QUIZ_ANSWER(quizSubmissionId)}`;

    const formData = new FormData();

    const transformUserAnswers = userAnswers.map((userAnswer) => {
      const uploadFiles = userAnswer.uploadFiles.map((uploadFile) => uploadFile.file.name);
      userAnswer.uploadFiles.forEach((uploadFile) => formData.append('files', uploadFile.file));
      return {
        ...userAnswer,
        uploadFiles,
      };
    });

    const transformPayload = {
      ...quizSubmission,
      userAnswers: [...transformUserAnswers],
    };

    formData.append('quizSubmission', JSON.stringify(transformPayload));

    return Http.put(apiUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  createOrUpdateFinishedLog(payload: {
    courseIdOrIdName: string;
    finishedLogPayload: CreateOrUpdateFinishedLogPayload;
  }) {
    const { courseIdOrIdName, finishedLogPayload } = payload;
    const apiUrl = `${config.API_DOMAIN}/${LEARNING_END_POINT.CREATE_OR_UPDATE_FINISHED_LOG(courseIdOrIdName)}`;
    return Http.post(apiUrl, finishedLogPayload);
  },

  fetchQuizSummaryScore(payload: any) {
    const { quizId } = payload;
    const apiUrl = `${config.API_DOMAIN}/${LEARNING_END_POINT.QUIZ_SUMMARY_SCORE(quizId)}`;
    return Http.get(apiUrl);
  },
  getQuizSubmission(quizId: number) {
    const apiUrl = `${config.API_DOMAIN}/${LEARNING_END_POINT.GET_QUIZ_SUBMISSION(quizId)}`;
    return Http.get(apiUrl);
  },
  checkAllowed(courseIdOrIdName: string) {
    const apiUrl = `${config.API_DOMAIN}/${LEARNING_END_POINT.CHECK_ALLOWED(courseIdOrIdName)}`;
    return Http.get(apiUrl, { isPublic: true });
  },
  markAsSeenCreditPlusAlertModal(courseIdOrIdName: string, enrollmentId: number) {
    const apiUrl = `${config.API_DOMAIN}/${LEARNING_END_POINT.MARK_AS_SEEN_CREDIT_PLUS_ALERT_MODAL(courseIdOrIdName)}`;
    return Http.patch(apiUrl, {
      enrollmentId,
    });
  },
  exportInteraction(courseId: number, params?: Record<string, unknown>) {
    const apiUrl = `${config.API_DOMAIN}/${LEARNING_END_POINT.EXPORT_INTERACTION(courseId)}`;

    return Http.get(apiUrl, { responseType: 'blob', params: pickBy(params, (v) => !!v) });
  },
  getCertificateProgression(courseIdOrIdName: string) {
    const apiUrl = new URL(LEARNING_END_POINT.GET_CERTIFICATE_PROGRESSION(courseIdOrIdName), config.API_DOMAIN);
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },
  updateLearningPathProgression(officialInfo: IUserOfficialInfo, enrollmentId: number | undefined) {
    const apiUrl = new URL(LEARNING_END_POINT.UPDATE_LEARNING_PATH_PROGRESSION(), config.API_DOMAIN);
    return httpClient
      .put(apiUrl.toString(), { officialInfo, enrollmentId })
      .then((res) => res.data)
      .catch((error) => error);
  },
};
