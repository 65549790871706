import { toCamelCase } from '@common/utility/data-entity/transform';
import { COURSE_END_POINT } from 'src/api/course';
import config from 'src/config';

import {
  FetchInstructorDropdownSuccessAction,
  FetchModuleCertificateAllCourseListRequestAction,
  FetchModuleCertificateCourseListRequest,
  FetchModuleCertificateCourseListResponse,
  FetchLearningPathListRequestAction,
  FetchLearningPathStudentListRequestAction,
  FetchLearningPathStudentListSuccessAction,
  HttpDeleteLearningPathApiResponse,
  PreviewCertificateRequest,
  SaveModuleCertificateCreateRequest,
  SearchCertificateBySlugRequest,
  UploadLearningPathImageRequest,
  DeleteLearningPathRequestAction,
  LearningPathStatusRequest,
  HttpFetchLearningPathListApiResponse,
  FetchLearningPathCourseBundleListRequestAction,
  FetchLearningPathCourseBundleListResponse,
  CreateLearningPathShareLogRequestAction,
  HttpFetchLearningPathDetailApiResponse,
  FetchMyLearningPathRequestAction,
} from './type';

import httpClient from '@helpers/httpClient';

export const moduleLearningPathAPI = {
  fetchModuleCertificateCourseList: async (
    payload: FetchModuleCertificateCourseListRequest,
  ): Promise<FetchModuleCertificateCourseListResponse> => {
    const apiUrl = new URL(COURSE_END_POINT.COURSES, config.API_DOMAIN);
    const request = {
      ...(payload.categoryId && { categoryId: payload.categoryId.join(',') }),
      ...(payload.subCategoryId && { subcategoryId: payload.subCategoryId.join(',') }),
    };
    return httpClient
      .get(apiUrl.toString(), { params: request })
      .then((res) => res.data)
      .catch((error) => error);
  },
  fetchLearningPathList: async (
    payload: FetchLearningPathListRequestAction,
  ): Promise<HttpFetchLearningPathListApiResponse> => {
    const { searchingWord, slug, status, page, pageSize } = payload;
    const apiUrl = new URL('v1/learning-path/search', config.API_DOMAIN);

    const request = {
      ...(searchingWord && { searchingWord }),
      ...(slug && { slug }),
      ...(status && { status }),
      page,
      pageSize,
    };
    return httpClient
      .get(apiUrl.toString(), { params: request })
      .then((res) => res.data)
      .catch((error) => error);
  },
  fetchModuleCertificateAllCourseList: async (
    payload: FetchModuleCertificateAllCourseListRequestAction,
  ): Promise<FetchModuleCertificateCourseListResponse> => {
    const { searchingWord, instructor, mainCategory, subCategory, page, pageSize } = payload;
    const apiUrl = new URL('v2/courses/search/course/learning-path', config.API_DOMAIN);
    const request = {
      searchingWord,
      instructorId: instructor?.id,
      categoryId: mainCategory?.id,
      subcategoryId: subCategory?.id,
      page,
      pageSize,
    };
    return httpClient
      .get(apiUrl.toString(), { params: request })
      .then((res) => res.data)
      .catch((error) => error);
  },
  createLearningPath: async (payload: SaveModuleCertificateCreateRequest) => {
    const apiUrl = new URL('v1/learning-path', config.API_DOMAIN);
    return httpClient
      .post(apiUrl.toString(), payload)
      .then((res) => res.data)
      .catch((error) => error);
  },
  updateLearningPath: async (payload: SaveModuleCertificateCreateRequest) => {
    const apiUrl = new URL('v1/learning-path', config.API_DOMAIN);
    return httpClient
      .put(apiUrl.toString(), payload)
      .then((res) => res.data)
      .catch((error) => error);
  },

  fetchModuleCertificateInstructorDropdown: async (): Promise<FetchInstructorDropdownSuccessAction> => {
    const apiUrl = new URL('v1/learning-path/instructors-dropdown', config.API_DOMAIN);
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },
  fetchLearningPath: async (payload: { id: number }) => {
    const apiUrl = new URL(`v1/learning-path/${payload.id}`, config.API_DOMAIN);
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },
  fetchLearningPathStudentList: async (
    payload: FetchLearningPathStudentListRequestAction,
  ): Promise<FetchLearningPathStudentListSuccessAction> => {
    const apiUrl = new URL('v1/learning-path/user/search', config.API_DOMAIN);
    const { searchingWord, id, email, status, page, pageSize } = payload;
    const queryParams = {
      page: String(page),
      pageSize: String(pageSize),
      learningPathId: id,
      ...(searchingWord && { searchingWord }),
      ...(email && { email }),
      ...(status && { status }),
    };

    return httpClient
      .get(apiUrl.toString(), { params: queryParams })
      .then((res) => res.data)
      .catch((error) => error);
  },
  fetchCertificateBySlugName: async (payload: SearchCertificateBySlugRequest) => {
    const apiUrl = new URL(`v1/core-certificate/${payload.slugName}`, config.API_DOMAIN);
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },
  uploadImage: async (payload: UploadLearningPathImageRequest) => {
    const apiUrl = new URL('v1/learning-path/upload-image', config.API_DOMAIN);
    apiUrl.searchParams.append('uploadPath', payload.uploadLearningPathType);
    return httpClient
      .post(apiUrl.toString(), payload.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => res.data)
      .catch((error) => error);
  },
  fetchPreviewCertificate: async (payload: PreviewCertificateRequest) => {
    const apiUrl = new URL('v1/core-certificate/preview', config.API_DOMAIN);
    return httpClient
      .post(apiUrl.toString(), payload)
      .then((res) => toCamelCase(res.data))
      .catch((error) => error);
  },
  deleteLearningPath: async (payload: DeleteLearningPathRequestAction): Promise<HttpDeleteLearningPathApiResponse> => {
    const apiUrl = new URL(`v1/learning-path/${payload.id}`, config.API_DOMAIN);
    return httpClient
      .delete(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },
  updateLearningPathStatus: async (payload: LearningPathStatusRequest) => {
    const apiUrl = new URL('v1/learning-path/status', config.API_DOMAIN);
    return httpClient
      .put(apiUrl.toString(), payload)
      .then((res) => res.data)
      .catch((error) => error);
  },
  fetchLearningPathCourseBundleList: async (
    payload: FetchLearningPathCourseBundleListRequestAction,
  ): Promise<FetchLearningPathCourseBundleListResponse> => {
    const apiUrl = new URL('v1/learning-path/course-bundle', config.API_DOMAIN);
    return httpClient
      .get(apiUrl.toString(), { params: payload })
      .then((res) => res.data)
      .catch((error) => error);
  },
  getLearningPathDetail(learningPathId: string): Promise<HttpFetchLearningPathDetailApiResponse> {
    const apiUrl = new URL(`v1/learning-path/my/detail/${learningPathId}`, config.API_DOMAIN);
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },
  getLearningPathDetailSeo(learningPathId: string): Promise<HttpFetchLearningPathDetailApiResponse> {
    const apiUrl = new URL(`v1/learning-path/seo/${learningPathId}`, config.API_DOMAIN);
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },
  createLearningPathShareLog(payload: CreateLearningPathShareLogRequestAction) {
    const apiUrl = new URL('v1/learning-path/create-learning-path-share-log', config.API_DOMAIN);
    return httpClient
      .post(apiUrl.toString(), payload)
      .then((res) => res.data)
      .catch((error) => error);
  },
  getMyModuleCertificate(bundleId: string) {
    const apiUrl = new URL(`v1/learning-path/my-certificates/${bundleId}`, config.API_DOMAIN);
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },
  getMyLearningPath(payload: FetchMyLearningPathRequestAction) {
    const apiUrl = new URL('v1/learning-path/me', config.API_DOMAIN);
    const queryParams = {
      keyword: payload?.keyword || '',
      status: payload?.status || '',
    };

    return httpClient
      .get(apiUrl.toString(), { params: queryParams })
      .then((res) => res.data)
      .catch((error) => error);
  },
};
