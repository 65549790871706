import { MainCourseCategoryDropdownOption } from 'src/types/learningPath';

const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'variant', caseFirst: 'upper' });

export const filterCourseCategory = (category: MainCourseCategoryDropdownOption[]) => {
  const categoryFiltered = category
    .filter((categoryItem) => categoryItem.enabled)
    .sort((a, b) => collator.compare(a.name, b.name));
  categoryFiltered.forEach((categoryItem) => {
    categoryItem.children = categoryItem.children
      ?.filter((subCategoryItem) => subCategoryItem.enabled)
      .sort((a, b) => collator.compare(a.name, b.name));
  });
  return categoryFiltered;
};
