import config from 'src/config';

import httpClient, { RequestConfig } from '@helpers/httpClient';
import {
  FetchManageUserCourseIdentityLogsRequestAction,
  HttpFetchManageUserCourseIdentityLogsApiResponse,
} from '@redux/manageUser/type/fetchManageUserCourseIdentityLogsType';
import {
  FetchManageUserIdentityListRequestAction,
  HttpFetchManageUserIdentityListApiResponse,
} from '@redux/manageUser/type/fetchManageUserIdentityListType';
import {
  FetchManageUserIdentityLogDetailRequestAction,
  HttpFetchManageUserIdentityLogDetailApiResponse,
} from '@redux/manageUser/type/fetchManageUserIdentityLogDetailType';
import {
  FetchManageUserIdentityLogSummaryRequestAction,
  HttpFetchManageUserIdentityLogSummaryApiResponse,
} from '@redux/manageUser/type/fetchManageUserIdentityLogSummaryType';
import {
  FetchManageUserIdentityLogRequestAction,
  HttpFetchManageUserIdentityLogApiResponse,
} from '@redux/manageUser/type/fetchManageUserIdentityLogType';

const manageUserAPI = {
  fetchManageUserIdentityList: async (
    payload: FetchManageUserIdentityListRequestAction,
  ): Promise<HttpFetchManageUserIdentityListApiResponse> => {
    const apiUrl = new URL('v1/manage/users/user-identities', config.API_DOMAIN);
    const requestConfig: RequestConfig = {
      params: {
        ...payload,
      },
    };
    return httpClient
      .get(apiUrl.toString(), requestConfig)
      .then((res) => res.data)
      .catch((error) => error);
  },

  fetchManageUserCourseIdentityLogs: async (
    payload: FetchManageUserCourseIdentityLogsRequestAction,
  ): Promise<HttpFetchManageUserCourseIdentityLogsApiResponse> => {
    const { userId, ...params } = payload;
    const apiUrl = new URL(`v1/manage/users/user-identities/${userId}/course-identity-logs`, config.API_DOMAIN);
    const requestConfig: RequestConfig = {
      params,
    };
    return httpClient
      .get(apiUrl.toString(), requestConfig)
      .then((res) => res.data)
      .catch((error) => error);
  },
  fetchManageUserIdentityLog: async (
    payload: FetchManageUserIdentityLogRequestAction,
  ): Promise<HttpFetchManageUserIdentityLogApiResponse> => {
    const { userId, ...newParams } = payload;
    const apiUrl = new URL(`v1/manage/users/${userId}/identity-logs`, config.API_DOMAIN);
    const requestConfig: RequestConfig = {
      params: {
        ...newParams,
      },
    };
    return httpClient
      .get(apiUrl.toString(), requestConfig)
      .then((res) => res.data)
      .catch((error) => error);
  },

  fetchManageUserIdentityLogSummary: async (
    payload: FetchManageUserIdentityLogSummaryRequestAction,
  ): Promise<HttpFetchManageUserIdentityLogSummaryApiResponse> => {
    const { userId, ...newParams } = payload;
    const apiUrl = new URL(`v1/manage/users/${userId}/identity-logs/summary`, config.API_DOMAIN);
    const requestConfig: RequestConfig = {
      params: {
        ...newParams,
      },
    };
    return httpClient
      .get(apiUrl.toString(), requestConfig)
      .then((res) => res.data)
      .catch((error) => error);
  },

  fetchManageUserIdentityLogDetail: async (
    payload: FetchManageUserIdentityLogDetailRequestAction,
  ): Promise<HttpFetchManageUserIdentityLogDetailApiResponse> => {
    const { faceComparisonId } = payload;
    const apiUrl = new URL(`v1/manage/users/identity-log/${faceComparisonId}`, config.API_DOMAIN);

    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },
};

export default manageUserAPI;
