import { camelCase, isPlainObject } from 'lodash';

export const toCamelCase = (data: any): any => {
  if (Array.isArray(data)) {
    return data.map(toCamelCase);
  } else if (isPlainObject(data)) {
    return Object.keys(data).reduce((acc, key) => {
      const camelKey = camelCase(key);
      acc[camelKey] = toCamelCase(data[key]);
      return acc;
    }, {} as Record<string, any>);
  }
  return data;
};
