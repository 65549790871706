import httpClient, { RequestConfig } from '@helpers/httpClient';
import { ICoreApiResponse } from '@interfaces/common';
import {
  IdentityDocumentConfirmationRequestAction,
  IdentityDocumentConfirmationState,
  IdentityDocumentConfirmationStatusResponse,
  IdentityDocumentExtractionRequestAction,
  IdentityDocumentExtractionState,
  IdentityDocumentExtractionStatusResponse,
  IdentityDocumentInformationRequestAction,
  IdentityDocumentInformationState,
  IdentityDocumentInformationStatusResponse,
  IdentityFaceComparisonRequestAction,
  IdentityFaceComparisonState,
  IdentityFaceComparisonStatusResponse,
  IdentityImageValidationRequestAction,
  IdentityImageValidationState,
  IdentityImageValidationStatusResponse,
} from '@redux/userIdentity/type';
import config from 'src/config';

const userIdentityAPI = {
  // eslint-disable-next-line max-len
  identityImageValidation: async (
    payload: IdentityImageValidationRequestAction,
  ): Promise<ICoreApiResponse<IdentityImageValidationState, IdentityImageValidationStatusResponse>> => {
    const apiUrl = new URL('v1/users/identity-verifications', config.API_DOMAIN);
    const requestConfig: RequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const formData = new FormData();
    formData.append('image', payload.image);
    formData.append('imageType', payload.imageType);
    return httpClient
      .post(apiUrl.toString(), formData, requestConfig)
      .then((res) => res.data)
      .catch((error) => error);
  },

  identityDocumentExtraction: async (
    payload: IdentityDocumentExtractionRequestAction,
  ): Promise<ICoreApiResponse<IdentityDocumentExtractionState, IdentityDocumentExtractionStatusResponse>> => {
    const apiUrl = new URL('v1/users/identity-extractions', config.API_DOMAIN);
    return httpClient
      .post(apiUrl.toString(), payload)
      .then((res) => res.data)
      .catch((error) => error);
  },

  identityDocumentConfirmation: async (
    payload: IdentityDocumentConfirmationRequestAction,
  ): Promise<ICoreApiResponse<IdentityDocumentConfirmationState, IdentityDocumentConfirmationStatusResponse>> => {
    const apiUrl = new URL('v1/users/identity-information-validation', config.API_DOMAIN);
    return httpClient
      .post(apiUrl.toString(), payload)
      .then((res) => res.data)
      .catch((error) => error);
  },

  identityFaceComparison: async (
    payload: IdentityFaceComparisonRequestAction,
  ): Promise<ICoreApiResponse<IdentityFaceComparisonState, IdentityFaceComparisonStatusResponse>> => {
    const apiUrl = new URL('v1/users/face-comparisons', config.API_DOMAIN);
    return httpClient
      .post(apiUrl.toString(), payload)
      .then((res) => res.data)
      .catch((error) => error);
  },

  identityDocumentInformation: async (
    payload: IdentityDocumentInformationRequestAction,
  ): Promise<ICoreApiResponse<IdentityDocumentInformationState, IdentityDocumentInformationStatusResponse>> => {
    const apiUrl = new URL('v1/users/identity-information', config.API_DOMAIN);
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },
};

export default userIdentityAPI;
