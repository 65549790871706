import { pickBy } from 'lodash';
import config from 'src/config';
import { SocialShareType } from 'src/helpers/constants';
import Http from 'src/helpers/httpClient';
import { IdOrIdName } from 'src/types/common';

const END_POINT = 'v1/courses';

export const COURSE_END_POINT = {
  COURSES: `${END_POINT}`,
  COURSE: (id: string) => `${END_POINT}/${id}`,
  COURSE_SHARE: (id: number, type: SocialShareType) => `${END_POINT}/${id}/counter/${type}`,
  COURSES_SECTION: `${END_POINT}/sections`,
  MY_COURSES: 'v1/enrollments/my-courses',
  SECTIONS: 'v1/sections',
  COUNT: `${END_POINT}/count`,
  LICENSE_TYPE: (regulator: string) => `${END_POINT}/license-types?regulator=${regulator}`,
  REVIEWS: (idOrIdName: string) => `${END_POINT}/${idOrIdName}/reviews`,
  REVIEWS_SUMMARY: (idOrIdName: string) => `${END_POINT}/${idOrIdName}/reviews/summaries`,
  UPDATE_REVIEW_SKIP_LOG: (idOrIdName: string) => `${END_POINT}/${idOrIdName}/reviews/review-skip-log`,
  GET_DISCUSSION: (courseIdOrIdName: IdOrIdName) => `${END_POINT}/${courseIdOrIdName}/discussions`,
  CREATE_DISCUSSION: (courseIdOrIdName: IdOrIdName) => `${END_POINT}/${courseIdOrIdName}/discussions`,
  CREATE_DISCUSSION_REPLY: (courseIdOrIdName: IdOrIdName, discussionId: number) =>
    `${END_POINT}/${courseIdOrIdName}/discussions/${discussionId}/replies`,
  GET_INTERACTION_SUMMARY: (courseIdOrIdName: IdOrIdName) =>
    `${END_POINT}/${courseIdOrIdName}/interactive-content/count`,
  GET_INTERACTION_LIST: (courseIdOrIdName: IdOrIdName) => `${END_POINT}/${courseIdOrIdName}/interactive-contents`,
};

export default {
  fetchCourses(path: string) {
    const apiUrl = `${config.API_DOMAIN}/${path}`;
    return Http.get(apiUrl, { isPublic: true });
  },
  fetchCourse(id: string) {
    const apiUrl = `${config.API_DOMAIN}/${COURSE_END_POINT.COURSE(id)}`;
    return Http.get(apiUrl, { isPublic: true });
  },
  fetchCourseBundle() {
    const apiUrl = `${config.API_DOMAIN}/${COURSE_END_POINT.COURSES_SECTION}/bundle`;
    return Http.get(apiUrl, { isPublic: true });
  },
  fetchCoursesBySection(path: string) {
    const apiUrl = `${config.API_DOMAIN}/${path}`;
    return Http.get(apiUrl, { isPublic: true });
  },
  fetchSections() {
    const apiUrl = `${config.API_DOMAIN}/${COURSE_END_POINT.SECTIONS}`;
    return Http.get(apiUrl, { isPublic: true });
  },
  fetchCourseCount() {
    const apiUrl = `${config.API_DOMAIN}/${COURSE_END_POINT.COUNT}`;
    return Http.get(apiUrl, { isPublic: true });
  },
  fetchSectionsWithData() {
    const apiUrl = `${config.API_DOMAIN}/${COURSE_END_POINT.SECTIONS}/data`;
    return Http.get(apiUrl, { isPublic: true });
  },
  updateShareCounter(id: number, shareType: SocialShareType) {
    const apiUrl = `${config.API_DOMAIN}/${COURSE_END_POINT.COURSE_SHARE(id, shareType)}`;
    return Http.put(apiUrl, { isPublic: true });
  },
  fetchLicenseType(regulator: string) {
    const apiUrl = `${config.API_DOMAIN}/${COURSE_END_POINT.LICENSE_TYPE(regulator)}`;
    return Http.get(apiUrl, { isPublic: true });
  },
  fetchReviews(payload: { courseIdOrIdName: string; limit: number }) {
    const { courseIdOrIdName, limit } = payload;
    const apiUrl = `${config.API_DOMAIN}/${COURSE_END_POINT.REVIEWS(courseIdOrIdName)}?limit=${limit}`;
    return Http.get(apiUrl, { isPublic: true });
  },
  fetchMyCourses(path: string) {
    const apiUrl = `${config.API_DOMAIN}/${path}`;
    return Http.get(apiUrl, { isPublic: false });
  },
  createOrUpdateReview(payload: {
    courseIdOrIdName: string;
    createOrUpdateReviewPayload: {
      rating: number;
      comment?: string;
    };
  }) {
    const { courseIdOrIdName, createOrUpdateReviewPayload } = payload;
    const apiUrl = `${config.API_DOMAIN}/${COURSE_END_POINT.REVIEWS(courseIdOrIdName)}`;
    return Http.patch(apiUrl, createOrUpdateReviewPayload);
  },
  fetchReviewsSummary(payload: { courseIdOrIdName: string }) {
    const { courseIdOrIdName } = payload;
    const apiUrl = `${config.API_DOMAIN}/${COURSE_END_POINT.REVIEWS_SUMMARY(courseIdOrIdName)}`;
    return Http.get(apiUrl, { isPublic: true });
  },
  updateReviewSkipLog(payload: { courseIdOrIdName: string }) {
    const { courseIdOrIdName } = payload;
    const apiUrl = `${config.API_DOMAIN}/${COURSE_END_POINT.UPDATE_REVIEW_SKIP_LOG(courseIdOrIdName)}`;
    return Http.put(apiUrl, {});
  },
  fetchDiscussion(courseIdOrIdName: IdOrIdName, params: { limit: number; keyword?: string; discussionId?: number }) {
    const { limit, keyword, discussionId } = params;

    const queryParams = new URLSearchParams();
    queryParams.append('limit', limit.toString());

    if (keyword) {
      queryParams.append('keyword', keyword);
    }

    if (discussionId) {
      queryParams.append('discussionId', discussionId.toString());
    }

    const apiUrl = `${config.API_DOMAIN}/${COURSE_END_POINT.GET_DISCUSSION(
      courseIdOrIdName,
    )}?${queryParams.toString()}`;
    return Http.get(apiUrl);
  },
  createDiscussion(
    courseIdOrIdName: IdOrIdName,
    payload: {
      title: string;
      chapterId?: number;
    },
  ) {
    const apiUrl = `${config.API_DOMAIN}/${COURSE_END_POINT.CREATE_DISCUSSION(courseIdOrIdName)}`;
    return Http.post(apiUrl, payload);
  },
  createDiscussionReply(
    courseIdOrIdName: IdOrIdName,
    discussionId: number,
    payload: {
      content: string;
      chapterId?: number;
    },
  ) {
    const apiUrl = `${config.API_DOMAIN}/${COURSE_END_POINT.CREATE_DISCUSSION_REPLY(courseIdOrIdName, discussionId)}`;
    return Http.post(apiUrl, payload);
  },
  fetchInteractionSummary(courseIdOrIdName: IdOrIdName) {
    const apiUrl = `${config.API_DOMAIN}/${COURSE_END_POINT.GET_INTERACTION_SUMMARY(courseIdOrIdName)}`;

    return Http.get(apiUrl);
  },
  fetchInteractionList(courseIdOrIdName: IdOrIdName, params?: Record<string, unknown>) {
    const apiUrl = `${config.API_DOMAIN}/${COURSE_END_POINT.GET_INTERACTION_LIST(courseIdOrIdName)}`;

    return Http.get(apiUrl, { params: pickBy(params, (v) => !!v) });
  },
};
