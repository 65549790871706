import config from 'src/config';

import httpClient from '@helpers/httpClient';
import {
  FetchBundleCoursesRequestAction,
  FetchBundleDetailRequestAction,
  FetchBundleDetailSeoRequestAction,
  FetchBundleEnrollmentRequestAction,
  HttpFetchBundleCoursesApiResponse,
  HttpFetchBundleDetailApiResponse,
  HttpFetchBundleDetailSeoApiResponse,
  HttpFetchBundleEnrollmentApiResponse,
} from '@redux/bundle/type/BundleDetailType';

const bundleApi = {
  fetchBundleDetailSeo: async (
    payload: FetchBundleDetailSeoRequestAction,
  ): Promise<HttpFetchBundleDetailSeoApiResponse> => {
    const apiUrl = new URL(`v1/bundles/${payload.idName}/seo`, config.API_DOMAIN);
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },

  fetchBundleDetail: async (payload: FetchBundleDetailRequestAction): Promise<HttpFetchBundleDetailApiResponse> => {
    const apiUrl = new URL(`v1/bundles/${payload.bundleIdOrIdName}`, config.API_DOMAIN);
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },

  fetchBundleCourses: async (payload: FetchBundleCoursesRequestAction): Promise<HttpFetchBundleCoursesApiResponse> => {
    const apiUrl = new URL(`v1/bundles/${payload.bundleIdOrIdName}/courses`, config.API_DOMAIN);
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },

  fetchBundleEnrollment: async (
    payload: FetchBundleEnrollmentRequestAction,
  ): Promise<HttpFetchBundleEnrollmentApiResponse> => {
    const apiUrl = new URL(`v1/bundles/${payload.bundleIdOrIdName}/enrollments`, config.API_DOMAIN);
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },
};

export default bundleApi;
